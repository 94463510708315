import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import USA from "@svg-maps/usa"
import Counties from "@svg-maps/usa.counties"
import Canada from "@svg-maps/canada"
// import World from "@svg-maps/world"
import { RadioSVGMap } from "react-svg-map"
// import "react-svg-map/lib/index.css"
// import _ from "lodash"
// import data from "../utils/data.json"

class StateToBuyPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedLocation: "al",
      type: [],
      electrical: "",
      lighting: "",
      seasonal: "",
    }

    this.handleOnChange = this.handleOnChange.bind(this)

    this.handleChecked = this.handleChecked.bind(this)
  }

  handleOnChange(selectedNode) {
    this.setState(prevState => {
      document
        .getElementById(prevState.selectedLocation)
        .setAttribute("aria-checked", false)
      return {
        ...prevState,
        selectedLocation: selectedNode.attributes.id.value,
      }
    })
  }

  handleSelect(abbr) {
    this.setState(prevState => {
      document
        .getElementById(prevState.selectedLocation)
        .setAttribute("aria-checked", false)
      return {
        ...prevState,
        selectedLocation: abbr,
      }
    })
    document.getElementById(abbr.toString()).setAttribute("aria-checked", true)
  }

  handleChecked(e) {
    let isChecked = e.target.checked
    this.setState({
      type: [...this.state.type, e.target.value],
    })

    if (!isChecked) {
      let myTypeFilter = this.state.type.filter(f => f !== e.target.value)
      this.setState({
        type: myTypeFilter,
      })
    }

    // console.log("e.target.value", e.target.value)
  }

  render() {
    // const uniqState = _.uniqBy(data, "id")
    // const crossCounty = [...USA.locations, ...Counties.locations]
    // const { electrical, lighting, seasonal } = this.state
    const allStates = this.props.data.allSanityStates.edges
    // const states = []
    // const newStates = []
    // const usState = []

    const filter = allStates.find(
      item => item.node.abbreviation === this.state.selectedLocation
    )

    // const typefiltered = _.uniqBy(filter, "type")
    // const typeSorted = _.sortBy(typefiltered, ["type"])

    // allStates.map(
    // 	test =>
    // 		test.node.abbreviation === this.state.selectedLocation &&
    // 		states.push(test)
    // )

    // data.map(
    // 	item =>
    // 		item.id === this.state.selectedLocation &&
    // 		newStates.push(item)
    // )
    // data.map(item => {
    // 	return item.id === this.state.selectedLocation && newStates.push(item)
    // })

    // crossCounty.map(
    // 	item => item.id === this.state.selectedLocation && usState.push(item)
    // )

    // const orderCity = _.orderBy(newStates, ["city"], ["asc"])
    // const uniqCity = _.uniqBy(orderCity, "city")

    // const groupedCity = _.groupBy(orderCity, item => item.city)

    // const filteredCity = [{ groupedCity }]
    // const mergStates = [...USA.locations, ...uniqState]

    // const filterState = _.uniqBy(mergStates, "name")
    // const orderState = _.orderBy(filterState, ["name"], ["asc"])

    // console.log("electrical", electrical)
    // console.log("city", uniqCity)
    // console.log("this.state.type", this.state)

    return (
      <Layout>
        <SEO title="Where to Buy" />
        <div className="page-headline">
          <div className="container">
            <div className="section-heading">
              <h1>Where to Buy</h1>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-8 mb-5 mb-lg-0">
                <div className="select-state-wrapper">
                  <p className="small text-muted">
                    Select a state or province listed below for showrooms and
                    distributors in your area.
                  </p>

                  <div className="d-flex">
                    <div className="pb-3">
                      <span className="mr-2">Select:</span>
                      <select onChange={e => this.handleSelect(e.target.value)}>
                        {allStates.map((values, index) => {
                          const abbreviation = values.node.abbreviation
                          return (
                            <option
                              value={abbreviation}
                              key={index}
                              selected={
                                abbreviation === this.state.selectedLocation
                                  ? true
                                  : false
                              }
                            >
                              {values.node.title}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="d-flex ml-3">
                      <div className="form-group mr-2 mb-md-0">
                        <input
                          onClick={this.handleChecked}
                          type="checkbox"
                          id="type-0"
                          value="Electrical Distributor"
                          name="type"
                        />
                        <label className="mb-0" htmlFor="type-0">
                          Electrical Distributor
                        </label>
                      </div>
                      <div className="form-group mr-2 mb-md-0">
                        <input
                          onClick={this.handleChecked}
                          type="checkbox"
                          id="type-1"
                          value="Lighting Showroom"
                          name="type"
                        />
                        <label className="mb-0" htmlFor="type-1">
                          Lighting Showroom
                        </label>
                      </div>
                      <div className="form-group mr-2 mb-md-0">
                        <input
                          onClick={this.handleChecked}
                          type="checkbox"
                          id="type-2"
                          value="Seasonal / Irrigation Supply"
                          name="type"
                        />
                        <label className="mb-0" htmlFor="type-2">
                          Seasonal / Irrigation Supply
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="map-container mt-5">
                    <div className="map-list map-usa px-md-4">
                      <RadioSVGMap map={USA} onChange={this.handleOnChange} />
                    </div>
                    <div className="map-list map-canada d-none">
                      <RadioSVGMap
                        map={Canada}
                        onChange={this.handleOnChange}
                      />
                      <RadioSVGMap
                        map={Counties}
                        onChange={this.handleOnChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                {filter?.node?.city?.length ? (
                  <React.Fragment>
                    <h3 className="section-heading">{filter.node.title}</h3>

                    <div className="row justify-content-start">
                      {filter.node.city.map((itemCity, index) => {
                        const filterStore =
                          this.state.type.length !== 0
                            ? this.state.type.length === 3
                              ? itemCity.stores.filter(
                                  f =>
                                    f.type === this.state.type[0] ||
                                    f.type === this.state.type[1] ||
                                    f.type === this.state.type[2]
                                )
                              : this.state.type.length === 2
                              ? itemCity.stores.filter(
                                  f =>
                                    f.type === this.state.type[0] ||
                                    f.type === this.state.type[1]
                                )
                              : this.state.type.length === 1
                              ? itemCity.stores.filter(
                                  f => f.type === this.state.type[0]
                                )
                              : null
                            : itemCity.stores
                        {
                          /* console.log("filterStore", filterStore) */
                        }

                        return (
                          <React.Fragment key={itemCity._key}>
                            {filterStore.length ? (
                              <div
                                className={`${
                                  filterStore.length >= 2
                                    ? "col-12"
                                    : "col-md-6"
                                } ${filterStore.length === 0 && "d-none"}`}
                              >
                                <h5
                                  className={`text-primary text-uppercase bordered-heading `}
                                >
                                  {itemCity.name}
                                </h5>
                                {filterStore.length && (
                                  <div className="row test">
                                    {filterStore.map((item, itemIndx) => (
                                      <div
                                        key={itemIndx}
                                        className={`${
                                          filterStore.length >= 2
                                            ? "col-md-6"
                                            : "col-12"
                                        } mb-4`}
                                      >
                                        <h6 className="font-weight-bold mb-0">
                                          {item.storeName}
                                        </h6>
                                        <p className="small text-primary">
                                          {item.type}
                                        </p>
                                        <p className="small text-muted mb-0">
                                          {item.streetAddress || "N/A"},{" "}
                                          <span className="text-uppercase">
                                            {this.state.selectedLocation}
                                          </span>{" "}
                                          {item.zipcode}
                                        </p>
                                        <p className="small text-muted mb-0">
                                          {item.phoneNumber !== null ? (
                                            <React.Fragment>
                                              PH:{" "}
                                              <a
                                                href={`tel:${item.phoneNumber}`}
                                              >
                                                {item.phoneNumber}
                                              </a>
                                            </React.Fragment>
                                          ) : null}
                                        </p>
                                        <p className="small text-muted mb-0">
                                          {item.website ? (
                                            <React.Fragment>
                                              <a
                                                href={item.website}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                Visit Website
                                              </a>
                                            </React.Fragment>
                                          ) : null}
                                        </p>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ) : null}
                          </React.Fragment>
                        )
                      })}
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <h3 className="section-heading mb-0">
                      {filter.node.title}
                    </h3>
                    <p>No locations available</p>
                  </React.Fragment>
                )}
              </div>
              {/*End*/}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default StateToBuyPage

export const statetobuyQuery = graphql`
  query statetobuyQuery {
    allSanityStates(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
          abbreviation
          city {
            _key
            name
            stores {
              _key
              zipcode
              streetAddress
              storeName
              phoneNumber
              website
              type
            }
          }
        }
      }
    }
  }
`
